<template>
  <div class="page-templates">
    <loading :active.sync="isLoadingTemplates" :is-full-page="false"></loading>
    <div class="d-flex mt4 justify-space-between"></div>
    <div class="search-box d-flex">
      <el-input placeholder="Search Templates" v-model="search"></el-input>
      <el-button type="warning" icon="el-icon-search"></el-button>
    </div>

    <masonry class="masonry-layout" :cols="4" :gutter="30">
      <div class="item" v-for="(template, index) in templates" :key="index">
        <div class="item-top">
          <img class="gallery-image" :src="template.preview" alt="" />
          <div class="hover">
            <el-button
              @click="thumbClicked(template)"
              class="btn-edit-chart"
              type="warning"
              ><strong>USE THIS TEMPLATE</strong></el-button
            >
            <el-button
              type="danger"
              class="mt2"
              @click="editTemplate(template)"
              v-if="isAdmin"
            >
              <strong> <i class="el-icon-edit"></i> EDIT </strong>
            </el-button>
          </div>
        </div>
        <div class="item-bottom">
          <p class="name">{{ template.file_name }}</p>
        </div>
      </div>
    </masonry>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedType: "All",
      search: "",
      isLoadingTemplates: false,
      chartTypes: [
        "All",
        "Line",
        "Area",
        "Column",
        "Bar",
        "Heatmap",
        "Radar",
        "Scatter",
        "Pie",
        "Donut",
        "RadialBar",
      ],
      templates: [],
    };
  },
  computed: {
    ...mapGetters("authModule", ["isAdmin"]),
    ...mapActions("projectsModule", ["getTemplates"]),

    _templates: function() {
      return this.templates.map((t) => {
        return t;
      });
    },
  },
  mounted: function() {
    this.isLoadingTemplates = true;

    this.$store
      .dispatch("projectsModule/getTemplates")
      .then((response) => {
        this.isLoadingTemplates = false;
        this.templates = response;
      })
      .catch((e) => {
        this.isLoadingTemplates = false;

        console.error(e);
      });
  },
  methods: {
    thumbClicked: function(template) {
      this.$router.push(`/c/new?template=${window.btoa(template._id)}`);
    },
    editTemplate: function(template) {
      this.$router.push(`/c/${template._id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "templates.component.scss";
</style>
